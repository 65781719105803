import { Component, RefObject, createRef } from "react";
import { Map, View, MapBrowserEvent, Feature } from "ol";
import { Geometry, Point } from "ol/geom";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource, XYZ } from "ol/source";
import { fromLonLat, toLonLat, transform } from "ol/proj";
import { getDistance } from "ol/sphere";
import RenderFeature from "ol/render/Feature";
import { createEmpty, extend } from "ol/extent";
import {
  getRenderScale,
  createEmptyVectorSource,
  createEmptyVectorLayer,
  createIconClusterLayer,
  createSnowmobilingIconFeature,
  createCommonPathFeatures,
  createCommonPathIconFeature,
  createEndcapIconFeature,
  createCommonPoiIconFeature,
  createSnowmobilePathFeatures,
  createGpsIndicatorFeature,
  postMessage,
  mapSnowmobileFraFeatures,
  mapAoiStyle,
} from "../../utils/map.utils";
import {
  ICONS,
  OBJECT_TYPE,
  OBJECT_ID,
  MAP_SRC,
  SAT_SRC,
  PROPS,
} from "../../constants/map.constants";
import {
  FeatureType,
  MapType,
  PathCategoryId,
  PathStatus,
} from "../../enums/map.enums";
import "./map-view.css";
import {
  MapCategories,
  MapCategoryProps,
  MapFilter,
  MapGpsCoordinate,
} from "../../interfaces/map/common.interfaces";
import { MapPath } from "../../interfaces/map/path.interface";
import { MapSnowmobileSubPath } from "../../interfaces/map/snowmobile-path.interface";
import { MapPoi } from "../../interfaces/map/poi.interface";
import AppContext from "../context/app-context";
import { COLORS } from "../../constants/app.constants";
import { SnowmobileFraResponse } from "../../interfaces/backend/snowmobile-paths.interface";

type MapViewProps = {
  //
};

class MapView extends Component<MapViewProps> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  // Map
  private map!: Map;
  private reference: RefObject<HTMLDivElement>;
  // Tile layers
  private mapLayer: TileLayer<XYZ>;
  private sateliteLayer: TileLayer<XYZ>;
  // Vector layers
  private highlightLayer: VectorLayer<VectorSource<Geometry>>;
  private snowmobilePathLayer: VectorLayer<VectorSource<Geometry>>;
  private pathLayer: VectorLayer<VectorSource<Geometry>>;
  private poiLayer: VectorLayer<VectorSource<Geometry>>;
  private gpsPositionLayer: VectorLayer<VectorSource<Geometry>>;
  private snowmobileIconLayer: VectorLayer<VectorSource<Geometry>>;
  private hikingIconLayer: VectorLayer<VectorSource<Geometry>>;
  private winterHikingIconLayer: VectorLayer<VectorSource<Geometry>>;
  private romboIconLayer: VectorLayer<VectorSource<Geometry>>;
  private cultureIconLayer: VectorLayer<VectorSource<Geometry>>;
  private crossCountrySkiingIconLayer: VectorLayer<VectorSource<Geometry>>;
  private mountainBikingIconLayer: VectorLayer<VectorSource<Geometry>>;
  private snowmobileFraLayer: VectorLayer<VectorSource<Geometry>>;
  // Vector sources
  private highlightSource: VectorSource<Geometry>;
  private snowmobilePathSource: VectorSource<Geometry>;
  private snowmobileFraSource: VectorSource<Geometry>;
  private pathSource: VectorSource<Geometry>;
  private poiSource: VectorSource<Geometry>;
  private gpsPositionSource: VectorSource<Geometry>;
  private snowmobileIconSource: VectorSource<Geometry>;
  private hikingIconSource: VectorSource<Geometry>;
  private winterHikingIconSource: VectorSource<Geometry>;
  private romboIconSource: VectorSource<Geometry>;
  private cultureIconSource: VectorSource<Geometry>;
  private crossCountrySkiingIconSource: VectorSource<Geometry>;
  private mountainBikingIconSource: VectorSource<Geometry>;
  // Previous values
  private previousHighlightedType?: FeatureType;
  private previousHighlightedId?: string;
  private previousSelectedType?: FeatureType;
  private previousSelectedId?: string;
  private previousMapType?: MapType;
  private previousFilters?: MapFilter[];
  private previosFocusedCategoryPaths?: MapFilter[] = [];
  private previousGpsPosition?: MapGpsCoordinate;
  private previousGpsCentered?: boolean;
  private previousGpsTime?: number;
  // Misc
  private pathCategoryProps?: MapCategoryProps;
  private poiCategoryProps?: MapCategoryProps;

  constructor(props: MapViewProps) {
    super(props);
    this.reference = createRef<HTMLDivElement>();

    // Create a layer for the drawn map (lantmäteriet)
    this.mapLayer = new TileLayer({
      source: new XYZ({
        url: MAP_SRC,
        maxZoom: 14,
      }),
    });

    // Create a layer for the satelite map
    this.sateliteLayer = new TileLayer({
      source: new XYZ({
        attributions: [
          "Powered by Esri",
          "Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community",
        ],
        attributionsCollapsible: false,
        url: SAT_SRC,
        maxZoom: 17,
      }),
    });

    // Create a new layer to render the highlight on
    this.highlightSource = createEmptyVectorSource();
    this.highlightLayer = createEmptyVectorLayer(this.highlightSource);

    // Create a new layer to render snowmobile paths on
    this.snowmobilePathSource = createEmptyVectorSource();
    this.snowmobilePathLayer = createEmptyVectorLayer(
      this.snowmobilePathSource
    );

    // Create a new layer to render paths on
    this.pathSource = createEmptyVectorSource();
    this.pathLayer = createEmptyVectorLayer(this.pathSource);

    // Create a new layer to render pois on
    this.poiSource = createEmptyVectorSource();
    this.poiLayer = createEmptyVectorLayer(this.poiSource);

    // Create a new layer to render GPS poisition on
    this.gpsPositionSource = createEmptyVectorSource();
    this.gpsPositionLayer = createEmptyVectorLayer(this.gpsPositionSource);

    // Create a new layer to render snowmobile icon cluster on
    this.snowmobileIconSource = createEmptyVectorSource();
    this.snowmobileIconLayer = createIconClusterLayer(
      this.snowmobileIconSource,
      ICONS.PATHS.SNOWMOBILING,
      COLORS.PATHS.SNOWMOBILING
    );

    // Create a new layer to render hiking icon cluster on
    this.hikingIconSource = createEmptyVectorSource();
    this.hikingIconLayer = createIconClusterLayer(
      this.hikingIconSource,
      ICONS.PATHS.HIKING,
      COLORS.PATHS.HIKING
    );

    // Create a new layer to render cross country skiing icon cluster on
    this.crossCountrySkiingIconSource = createEmptyVectorSource();
    this.crossCountrySkiingIconLayer = createIconClusterLayer(
      this.crossCountrySkiingIconSource,
      ICONS.PATHS.CROSS_COUNTRY_SKIING,
      COLORS.PATHS.CROSS_COUNTRY_SKIING
    );

    // Create a new layer to render mountain biking icon cluster on
    this.mountainBikingIconSource = createEmptyVectorSource();
    this.mountainBikingIconLayer = createIconClusterLayer(
      this.mountainBikingIconSource,
      ICONS.PATHS.MOUNTAIN_BIKING,
      COLORS.PATHS.MOUNTAIN_BIKING
    );

    // Create a new layer to render winter hiking icon cluster on
    this.winterHikingIconSource = createEmptyVectorSource();
    this.winterHikingIconLayer = createIconClusterLayer(
      this.winterHikingIconSource,
      ICONS.PATHS.WINTER_HIKING,
      COLORS.PATHS.WINTER_HIKING
    );

    // Create a new layer to render Rombo icon cluster on
    this.romboIconSource = createEmptyVectorSource();
    this.romboIconLayer = createIconClusterLayer(
      this.romboIconSource,
      ICONS.PATHS.ROMBO,
      COLORS.PATHS.ROMBO
    );

    // Create a new layer to render culture icon cluster on
    this.cultureIconSource = createEmptyVectorSource();
    this.cultureIconLayer = createIconClusterLayer(
      this.cultureIconSource,
      ICONS.PATHS.CULTURES,
      COLORS.PATHS.CULTURES
    );

    this.snowmobileFraSource = createEmptyVectorSource();
    this.snowmobileFraLayer = createEmptyVectorLayer(this.snowmobileFraSource);
  }

  componentDidMount() {
    // Create the OL Map
    this.map = new Map({
      target: this.reference.current!,
      layers: [
        this.mapLayer,
        this.sateliteLayer,
        this.poiLayer,
        this.snowmobilePathLayer,
        this.pathLayer,
        this.snowmobileIconLayer,
        this.hikingIconLayer,
        this.winterHikingIconLayer,
        this.romboIconLayer,
        this.cultureIconLayer,
        this.mountainBikingIconLayer,
        this.crossCountrySkiingIconLayer,
        this.highlightLayer,
        this.gpsPositionLayer,
        this.snowmobileFraLayer,
      ],
      view: new View({
        center: fromLonLat([12.545782718807459, 62.545728189870715]),
        zoom: 6,
        minZoom: 6,
        maxZoom: 16,
      }),
      ...(process.env.REACT_APP_STANDALONE !== "true" && { controls: [] }),
    });

    // Register OL Map events
    this.map.on("singleclick", (event) => this.handleSingleClick(event));
    this.map.on("pointerdrag", (event) => this.handlePointerDrag(event));

    // set initial map layer
    this.setMapType(this.context.mapType);
  }

  componentDidUpdate(prevProps: MapViewProps) {
    // Check if filters have been altered, if so, recalculate categoryprops and redraw
    const {
      mapFilters,
      mapPathCategories,
      mapPoiCategories,
      focusedCategoryPaths,
    } = this.context;

    if (JSON.stringify(this.previousFilters) !== JSON.stringify(mapFilters)) {
      if (mapPathCategories) {
        this.setPathCategoryProps(mapPathCategories);
      }
      if (mapPoiCategories) {
        this.setPoiCategoryProps(mapPoiCategories);
      }

      this.previousFilters = mapFilters;

      this.redraw();
      setTimeout(() => {
        this.refocus();
      });
    }

    if (
      JSON.stringify(this.previosFocusedCategoryPaths) !==
      JSON.stringify(focusedCategoryPaths)
    ) {
      this.setPathCategoryProps(mapPathCategories);
      this.previosFocusedCategoryPaths = focusedCategoryPaths;
      this.redraw();
      setTimeout(() => {
        this.refocus();
      });
    }

    // Check if highlight has been changed, if so, redraw highlight
    const { highlightedType, highlightedId } = this.context;
    if (
      this.previousHighlightedType !== highlightedType ||
      this.previousHighlightedId !== highlightedId
    ) {
      this.previousHighlightedType = highlightedType;
      this.previousHighlightedId = highlightedId;
      this.redrawHighlight();
    }

    // Check if selection has been changed, if so, set highlight there and refocus
    const { selectedType, selectedId, setHighlighted } = this.context;
    if (
      this.previousSelectedType !== selectedType ||
      this.previousSelectedId !== selectedId
    ) {
      this.previousSelectedType = selectedType;
      this.previousSelectedId = selectedId;
      if (
        this.previousHighlightedType !== selectedType ||
        this.previousHighlightedId !== selectedId
      ) {
        setHighlighted(selectedType, selectedId);
        setTimeout(() => {
          this.refocus();
        }, 5);
      }
    }

    // Check if maptype was changed, if so, set it as the new maptype
    const { mapType } = this.context;
    if (this.previousMapType !== mapType) {
      this.previousMapType = mapType;
      this.setMapType(mapType);
    }

    // Check if GPS position was set or changed, if so, set this as the new GPS position
    const { gpsPosition } = this.context;
    if (
      this.previousGpsPosition?.longitude !== gpsPosition?.longitude ||
      this.previousGpsPosition?.latitude !== gpsPosition?.latitude
    ) {
      if (gpsPosition) {
        this.setGpsPosition(gpsPosition);
      }
      this.previousGpsPosition = gpsPosition;
    }

    // Check if GPS centered has been set, if so, center on the GPS position
    const { gpsCentered } = this.context;
    if (this.previousGpsCentered !== gpsCentered) {
      if (gpsCentered) {
        this.centerOnGps();
      }
      this.previousGpsCentered = gpsCentered;
    }
  }

  render() {
    return <div ref={this.reference} className="MapView" />;
  }

  handleSingleClick(event: MapBrowserEvent<any>) {
    let hitFeature: undefined | RenderFeature | Feature<Geometry>;

    // Itterate each point and see if we hit any feature
    this.map.forEachFeatureAtPixel(
      event.pixel,
      (feature) => {
        hitFeature = feature;
      },
      {
        hitTolerance: 11 * getRenderScale(),
      }
    );

    // // Check if we have data within the detected feature, early return if not
    const type = hitFeature?.get(OBJECT_TYPE);
    const id = hitFeature?.get(OBJECT_ID);
    if (!hitFeature || !type || !id) {
      postMessage(JSON.stringify({ deselect: true }));

      this.context.setHighlighted();
      return;
    }

    postMessage(JSON.stringify({ type, id }));
    this.context.setHighlighted(type, id);
  }

  handlePointerDrag(event: MapBrowserEvent<any>) {
    if (this.context.gpsCentered) {
      this.context.setGpsCentered(false);
    }
  }

  setPoiCategoryProps(mapPoiCategories: MapCategories) {
    this.poiCategoryProps = {};
    for (const [key, value] of Object.entries(mapPoiCategories)) {
      // Save Identifier data for this poi id
      this.poiCategoryProps[key] = (PROPS.POIS as any)[value.identifier];
    }
  }

  setPathCategoryProps(mapPathCategories: MapCategories) {
    this.pathCategoryProps = {};
    for (const [key, value] of Object.entries(mapPathCategories)) {
      // Save Identifier data for this path id
      this.pathCategoryProps[key] = (PROPS.PATHS as any)[value.identifier];
    }
  }

  setMapType(type: MapType) {
    this.mapLayer.setVisible(type === MapType.Map);
    this.sateliteLayer.setVisible(type === MapType.Satelite);

    this.redraw();
    this.redrawHighlight();
  }

  get hasDarkBackground(): boolean {
    return this.context.mapType === MapType.Satelite;
  }

  setGpsPosition(gpsPosition: MapGpsCoordinate) {
    this.gpsPositionSource.clear();

    if (gpsPosition) {
      const { longitude, latitude } = gpsPosition;
      const coordinate = fromLonLat([longitude, latitude]);
      const point = new Point(coordinate);

      this.gpsPositionSource.addFeatures([createGpsIndicatorFeature(point)]);
      this.reportNearestFeature(coordinate);
    }
  }

  redraw() {
    let { mapFilters, focusedCategoryPaths } = this.context;

    this.pathSource.clear();
    this.snowmobilePathSource.clear();
    this.poiSource.clear();
    this.snowmobileIconSource.clear();
    this.hikingIconSource.clear();
    this.winterHikingIconSource.clear();
    this.romboIconSource.clear();
    this.cultureIconSource.clear();
    this.crossCountrySkiingIconSource.clear();
    this.mountainBikingIconSource.clear();
    this.snowmobileFraSource.clear();

    if (focusedCategoryPaths?.length !== 0) {
      mapFilters = focusedCategoryPaths;
    }

    if (!mapFilters?.length) {
      this.drawPaths([]);
      this.drawSnowmobilePaths([]);
      this.drawPois([]);
      this.drawSnowmobileFras();
    } else {
      mapFilters.forEach((mapFilter: MapFilter) => {
        switch (mapFilter.type) {
          case FeatureType.Path:
            this.drawPaths(mapFilter.ids);
            break;

          case FeatureType.SnowmobilePath:
            this.drawSnowmobilePaths(mapFilter.ids);
            break;

          case FeatureType.SnowmobileSubPath:
            this.drawSnowmobileSubPaths(mapFilter.ids);
            break;

          case FeatureType.Poi:
            this.drawPois(mapFilter.ids);
            break;

          case FeatureType.SnowmobileFra:
            this.drawSnowmobileFras();
            break;

          default:
            break;
        }
      });
    }
  }

  drawPaths(ids: string[]) {
    const { mapPaths } = this.context;

    if (!mapPaths || !Object.keys(mapPaths).length) {
      return;
    }

    const crossCountrySkiingLineFeatures: Feature<Geometry>[] = [];
    const crossCountrySkiingIconFeatures: Feature<Geometry>[] = [];
    const crossCountrySkiingEndcapFeatures: Feature<Geometry>[] = [];

    const mountainBikingLineFeatures: Feature<Geometry>[] = [];
    const mountainBikingIconFeatures: Feature<Geometry>[] = [];
    const mountainBikingEndcapFeatures: Feature<Geometry>[] = [];

    const hikingLineFeatures: Feature<Geometry>[] = [];
    const hikingIconFeatures: Feature<Geometry>[] = [];
    const hikingEndcapFeatures: Feature<Geometry>[] = [];

    const winterHikingLineFeatures: Feature<Geometry>[] = [];
    const winterHikingIconFeatures: Feature<Geometry>[] = [];
    const winterHikingEndcapFeatures: Feature<Geometry>[] = [];

    const romboLineFeatures: Feature<Geometry>[] = [];
    const romboIconFeatures: Feature<Geometry>[] = [];
    const romboEndcapFeatures: Feature<Geometry>[] = [];

    const cultureLineFeatures: Feature<Geometry>[] = [];
    const cultureIconFeatures: Feature<Geometry>[] = [];
    const cultureEndcapFeatures: Feature<Geometry>[] = [];

    Object.values(mapPaths).forEach((path) => {
      if (!ids.length || ids.includes(path.id)) {
        //Cross country skiing paths
        if (path.pathCategoryId === PathCategoryId.CrossCountrySkiing) {
          this.drawPath(
            crossCountrySkiingLineFeatures,
            crossCountrySkiingIconFeatures,
            crossCountrySkiingEndcapFeatures,
            path
          );
        }

        //Cross country skiing paths
        if (path.pathCategoryId === PathCategoryId.MountainBiking) {
          this.drawPath(
            mountainBikingLineFeatures,
            mountainBikingIconFeatures,
            mountainBikingEndcapFeatures,
            path
          );
        }

        //Hiking paths
        if (path.pathCategoryId === PathCategoryId.Hiking) {
          this.drawPath(
            hikingLineFeatures,
            hikingIconFeatures,
            hikingEndcapFeatures,
            path
          );
        }

        //Winter Hiking paths
        if (path.pathCategoryId === PathCategoryId.WinterHiking) {
          this.drawPath(
            winterHikingLineFeatures,
            winterHikingIconFeatures,
            winterHikingEndcapFeatures,
            path
          );
        }

        //Rombo paths
        if (path.pathCategoryId === PathCategoryId.Rombo) {
          this.drawPath(
            romboLineFeatures,
            romboIconFeatures,
            romboEndcapFeatures,
            path
          );
        }

        //Culture paths
        if (path.pathCategoryId === PathCategoryId.Cultures) {
          this.drawPath(
            cultureLineFeatures,
            cultureIconFeatures,
            cultureEndcapFeatures,
            path
          );
        }
      }
    });

    this.pathSource.addFeatures([
      ...crossCountrySkiingLineFeatures,
      ...crossCountrySkiingEndcapFeatures,
    ]);
    this.crossCountrySkiingIconSource.addFeatures([
      ...crossCountrySkiingIconFeatures,
    ]);
    this.pathSource.addFeatures([
      ...mountainBikingLineFeatures,
      ...mountainBikingEndcapFeatures,
    ]);
    this.mountainBikingIconSource.addFeatures([...mountainBikingIconFeatures]);
    this.pathSource.addFeatures([
      ...hikingLineFeatures,
      ...hikingEndcapFeatures,
    ]);
    this.hikingIconSource.addFeatures([...hikingIconFeatures]);

    this.winterHikingIconSource.addFeatures([...winterHikingIconFeatures]);
    this.pathSource.addFeatures([
      ...winterHikingLineFeatures,
      ...winterHikingEndcapFeatures,
    ]);

    this.romboIconSource.addFeatures([...romboIconFeatures]);
    this.pathSource.addFeatures([...romboLineFeatures, ...romboEndcapFeatures]);

    this.cultureIconSource.addFeatures([...cultureIconFeatures]);
    this.pathSource.addFeatures([
      ...cultureLineFeatures,
      ...cultureEndcapFeatures,
    ]);
  }

  drawPath(
    lineFeatures: Feature<Geometry>[],
    iconFeatures: Feature<Geometry>[],
    endcapFeatures: Feature<Geometry>[],
    path: MapPath,
    isBold: boolean = false
  ) {
    if (!this.pathCategoryProps) {
      return;
    }

    const { mapPathGpsCoordinates } = this.context;
    if (!mapPathGpsCoordinates || !mapPathGpsCoordinates[path.id]) {
      return;
    }

    const { color, icon, closedIcon } =
      this.pathCategoryProps[path.pathCategoryId];
    const lineCoordinates = mapPathGpsCoordinates[path.id].map(
      (coordinate, index) =>
        fromLonLat([coordinate.longitude, coordinate.latitude, index])
    );

    lineFeatures.push(
      ...createCommonPathFeatures(
        lineCoordinates,
        path.id,
        color,
        path.status === PathStatus.Closed,
        isBold
      )
    );

    const endIndex = lineCoordinates.length - 1;
    const midIndex = Math.floor(endIndex / 2);

    endcapFeatures.push(
      createEndcapIconFeature(
        new Point(lineCoordinates[0]),
        path.start,
        this.hasDarkBackground
      )
    );
    endcapFeatures.push(
      createEndcapIconFeature(
        new Point(lineCoordinates[endIndex]),
        path.end,
        this.hasDarkBackground
      )
    );

    iconFeatures.push(
      createCommonPathIconFeature(
        new Point(lineCoordinates[midIndex]),
        path.id,
        path.number,
        color,
        path.status !== PathStatus.Closed ? icon : closedIcon,
        isBold
      )
    );
  }

  drawSnowmobileFras() {
    const lineFeatures: Feature<Geometry>[] = [];
    const iconFeatures: Feature<Geometry>[] = [];

    const { mapSnowmobileFras } = this.context;
    Object.values(mapSnowmobileFras).forEach((fra) => {
      this.drawSnowmobileFra(lineFeatures, iconFeatures, fra);
    });

    this.snowmobileFraSource.addFeatures([...lineFeatures, ...iconFeatures]);
  }

  drawSnowmobileFra(
    lineFeatures: Feature<Geometry>[],
    iconFeatures: Feature<Geometry>[],
    fra: SnowmobileFraResponse,
    isBold: boolean = false
  ) {
    const lineCoordinates = fra.fraGpsCoordinates.map((coordinate, index) =>
      fromLonLat([coordinate.longitude, coordinate.latitude, index])
    );

    const xCoordinates = lineCoordinates.map((coordinate) => coordinate[0]);
    xCoordinates.sort((a, b) => a - b);
    const yCoordinates = lineCoordinates.map((coordinate) => coordinate[1]);
    yCoordinates.sort((a, b) => a - b);
    const center = [
      (xCoordinates[0] + xCoordinates[xCoordinates.length - 1]) / 2,
      (yCoordinates[yCoordinates.length - 1] + yCoordinates[0]) / 2,
    ];
    lineFeatures.push(
      ...mapSnowmobileFraFeatures(
        lineCoordinates,
        fra.id.toString(),
        [
          {
            name: fra.name,
            color: COLORS.PATHS.SNOW_MOBILE_FRA,
            position: 0.5,
          },
        ],
        mapAoiStyle(COLORS.PATHS.SNOW_MOBILE_FRA, 1, isBold ? 16 : 0),
        center
      )
    );

    // iconFeatures.push(snowmobilingFraIcon(center, fra.name, isBold));
  }

  drawSnowmobilePaths(ids: string[]) {
    const lineFeatures: Feature<Geometry>[] = [];
    const iconFeatures: Feature<Geometry>[] = [];
    const { mapSnowmobilePaths, mapSnowmobileSubPaths } = this.context;
    if (ids?.length) {
      ids.forEach((id) => {
        const path = mapSnowmobilePaths[id];

        path?.subPaths.forEach((subPathId) => {
          const subPath = mapSnowmobileSubPaths[subPathId];

          this.drawSnowmobileSubPath(lineFeatures, iconFeatures, subPath);
        });
        if (path?.returnSubPathIds) {
          path.returnSubPathIds.forEach((subPathId, index) => {
            const subPath = mapSnowmobileSubPaths[subPathId];
            const gpsCoords = subPath.subPathGPSCoordinates.map((coord) => ({
              longitude: coord.longitude + 0.0001,
              latitude: coord.latitude + 0.0002,
            }));
            this.drawSnowmobileSubPath(
              lineFeatures,
              iconFeatures,
              {
                ...subPath,
                id: subPath.id,
                subPathGPSCoordinates: gpsCoords,
                start: undefined,
                end: undefined,
              },
              false,
              COLORS.PATHS.SNOWMOBILING_RETURN
            );
          });
        }
      });
    } else {
      Object.values(mapSnowmobileSubPaths).forEach((subPath) => {
        this.drawSnowmobileSubPath(lineFeatures, iconFeatures, subPath);
      });
    }

    this.snowmobilePathSource.addFeatures([...lineFeatures, ...iconFeatures]);
    this.snowmobileIconSource.addFeatures([]); // NOTE: Empty at the moment as many paths icons for crossings overlap, resulting in always being clustered
  }

  drawSnowmobileSubPaths(ids: string[]) {
    const lineFeatures: Feature<Geometry>[] = [];
    const iconFeatures: Feature<Geometry>[] = [];
    const { mapSnowmobileSubPaths } = this.context;

    if (ids?.length) {
      Object.values(mapSnowmobileSubPaths).forEach((subPath) => {
        if (ids.includes(subPath.id)) {
          this.drawSnowmobileSubPath(lineFeatures, iconFeatures, subPath);
        }
      });
    }

    this.snowmobilePathSource.addFeatures([...lineFeatures, ...iconFeatures]);
    this.snowmobileIconSource.addFeatures([]); // NOTE: Empty at the moment as many paths icons for crossings overlap, resulting in always being clustered
  }

  drawSnowmobileSubPath(
    lineFeatures: Feature<Geometry>[],
    iconFeatures: Feature<Geometry>[],
    subPath: MapSnowmobileSubPath,
    isBold: boolean = false,
    color?: string
  ) {
    const lineCoordinates = subPath.subPathGPSCoordinates.map((coordinate) =>
      fromLonLat([coordinate.longitude, coordinate.latitude])
    );

    lineFeatures.push(
      ...createSnowmobilePathFeatures(
        lineCoordinates,
        subPath.id,
        subPath.status === PathStatus.Closed,
        isBold,
        color
      )
    );

    if (subPath.start) {
      iconFeatures.push(
        createSnowmobilingIconFeature(
          new Point(lineCoordinates[0]),
          subPath.start,
          isBold
        )
      );
    }

    if (subPath.end) {
      const endIndex = lineCoordinates.length - 1;
      iconFeatures.push(
        createSnowmobilingIconFeature(
          new Point(lineCoordinates[endIndex]),
          subPath.end,
          isBold
        )
      );
    }
  }

  drawPois(ids: string[]) {
    const iconFeatures: Feature<Geometry>[] = [];

    const { mapPois } = this.context;
    Object.values(mapPois).forEach((poi) => {
      if (!ids.length || ids.includes(poi.id)) {
        this.drawPoi(iconFeatures, poi);
      }
    });

    this.poiSource.addFeatures([...iconFeatures]);
  }

  drawPoi(
    iconFeatures: Feature<Geometry>[],
    poi: MapPoi,
    isBold: boolean = false
  ) {
    if (!this.poiCategoryProps) {
      return;
    }
    const { color, icon } = this.poiCategoryProps[poi.poiCategoryId];
    const coordinates = fromLonLat([poi.longitude, poi.latitude]);

    const { renderedInApp } = this.context;
    iconFeatures.push(
      createCommonPoiIconFeature(
        new Point(coordinates),
        poi.id,
        renderedInApp ? "" : poi.name,
        color,
        icon,
        this.hasDarkBackground,
        isBold
      )
    );
  }

  redrawHighlight() {
    const lineFeatures: Feature<Geometry>[] = [];
    const iconFeatures: Feature<Geometry>[] = [];
    const {
      mapPaths,
      mapSnowmobilePaths,
      mapSnowmobileSubPaths,
      mapPois,
      highlightedType,
      highlightedId,
    } = this.context;
    this.highlightSource.clear();

    if (!highlightedId) {
      return;
    }

    switch (highlightedType) {
      case FeatureType.Path:
        const path = mapPaths[`${highlightedId}`];
        this.drawPath(lineFeatures, iconFeatures, iconFeatures, path, true);
        break;

      case FeatureType.SnowmobilePath:
        this.drawSnowmobilePaths([highlightedId]);
        break;

      case FeatureType.SnowmobileSubPath:
        const snowmobileSubPath = mapSnowmobileSubPaths[highlightedId];
        this.drawSnowmobileSubPath(
          lineFeatures,
          iconFeatures,
          snowmobileSubPath,
          true
        );
        break;

      case FeatureType.Poi:
        const poi = mapPois[`${highlightedId}`];
        this.drawPoi(iconFeatures, poi, true);
        break;

      default:
        break;
    }

    this.highlightSource.addFeatures([...lineFeatures, ...iconFeatures]);
  }

  refocus() {
    const { selectedType, selectedId, mapSnowmobilePaths } = this.context;
    let source = null;
    switch (selectedType) {
      case FeatureType.Path:
        source = this.pathSource;
        break;

      case FeatureType.SnowmobilePath:
      case FeatureType.SnowmobileSubPath:
        source = this.snowmobilePathSource;
        break;

      case FeatureType.SnowmobileFra:
        source = this.snowmobileFraSource;
        break;
      case FeatureType.Poi:
        source = this.poiSource;
        break;

      default:
        break;
    }

    if (source?.getFeatures().length) {
      if (selectedId !== undefined) {
        let extent = createEmpty();

        if (selectedType === FeatureType.SnowmobilePath) {
          const subPathIds = mapSnowmobilePaths[selectedId]?.subPaths;

          if (subPathIds?.length) {
            source.getFeatures().forEach((feature) => {
              subPathIds.forEach((subPathId) => {
                const type = feature.get(OBJECT_TYPE);
                const id = feature.get(OBJECT_ID);

                if (
                  type === FeatureType.SnowmobileSubPath &&
                  id === subPathId
                ) {
                  extend(extent, feature!.getGeometry()!.getExtent());
                }
              });
            });
          }
        } else {
          source.getFeatures().forEach((feature) => {
            const type = feature.get(OBJECT_TYPE);
            const id = feature.get(OBJECT_ID);

            if (type === selectedType && id === selectedId) {
              extend(extent, feature!.getGeometry()!.getExtent());
            }
          });
        }

        this.map.getView().fit(extent, {
          padding: [50, 50, 50, 50],
        });
      } else {
        const extent = source.getExtent();
        this.map.getView().fit(extent);
      }
    } else {
      let extent = createEmpty();
      extend(extent, this.pathSource.getExtent());
      extend(extent, this.snowmobilePathSource.getExtent());
      extend(extent, this.poiSource.getExtent());
      this.map.getView().fit(extent);
    }
  }

  getCoordsDistance(
    coordinate1: number[],
    coordinate2: number[],
    projection?: string
  ): number {
    projection = projection || "EPSG:4326";

    const sourceProj = this.map.getView().getProjection();
    const c1 = transform(coordinate1, sourceProj, projection);
    const c2 = transform(coordinate2, sourceProj, projection);

    return getDistance(c1, c2);
  }

  reportNearestFeature(coordinate: number[]) {
    const now = Date.now();

    if (!this.previousGpsTime || now - this.previousGpsTime >= 1000) {
      const closestFeature =
        this.pathSource.getClosestFeatureToCoordinate(coordinate);

      if (closestFeature) {
        const closestPoint = closestFeature!
          .getGeometry()!
          .getClosestPoint(coordinate);

        if (this.getCoordsDistance(closestPoint, coordinate) > 70) {
          return;
        }

        const objectId = closestFeature.get(OBJECT_ID);
        if (!objectId) {
          return;
        }

        const latLongCoords = toLonLat(closestPoint);
        const msg = JSON.stringify({
          pathId: parseInt(objectId, 10),
          pointCoordinate: {
            x: latLongCoords[0],
            y: latLongCoords[1],
            ...(closestPoint[2] && { index: closestPoint[2] }),
          },
        });
        postMessage(msg);
      } else {
        const msg = JSON.stringify({
          pathId: 0,
          pointCoordinate: {
            x: 0,
            y: 0,
          },
        });
        postMessage(msg);
      }
      this.previousGpsTime = now;
    }
  }

  centerOnGps() {
    if (this.gpsPositionSource.getFeatures().length) {
      const view = this.map.getView();
      view.fit(this.gpsPositionSource.getExtent());
      view.setRotation(0);
    }
  }
}

export default MapView;
