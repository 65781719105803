import { Geometry, LineString, Point, Polygon } from "ol/geom";
import { Vector as VectorLayer, VectorImage } from "ol/layer";
import { Vector as VectorSource, Cluster } from "ol/source";
import {
  Style,
  Fill,
  Stroke,
  Text,
  Icon,
  Circle as CircleStyle,
} from "ol/style";
import { asArray as colorAsArray, asString as colorAsString } from "ol/color";
import { Feature } from "ol";
import { ICONS, OBJECT_TYPE, OBJECT_ID } from "../constants/map.constants";
import { FeatureType } from "../enums/map.enums";
import { COLORS } from "../constants/app.constants";

const DEFAULT_ICON_FONT = "scoutBold";
const DEFAULT_PATH_FONT = "scoutRegular";
const CLOSED_TEXT = "Stängd";
let renderScale = 0.5;

/**
 * Gets the render scale variable
 */
export function getRenderScale(): number {
  return renderScale;
}

/**
 * Sets the render scale variable
 */
export function setRenderScale(scale: number) {
  renderScale = scale;
}

/**
 * Creates a empty vector source
 */
export function createEmptyVectorSource(): VectorSource<Geometry> {
  return new VectorSource({
    features: [],
  });
}

/**
 * Creates a empty vector layer for a source
 */
export function createEmptyVectorLayer(
  source: VectorSource<Geometry>
): VectorLayer<VectorSource<Geometry>> {
  return new VectorLayer({
    source,
  });
}

/**
 * Creates a cluster layer for the given vector source using the given icon path and color
 */
export function createIconClusterLayer(
  source: VectorSource<Geometry>,
  path: string,
  color: string
): VectorLayer<VectorSource<Geometry>> {
  const cluster = new Cluster({
    distance: 40,
    source,
  });

  return new VectorLayer({
    source: cluster,
    style: (feature) => {
      const size = feature.get("features").length;

      if (size === 1) {
        return feature.get("features")[0]["style_"];
      }

      return createBaseMapIconStyle(
        path,
        `(${size})`,
        color,
        30,
        "scoutBold",
        45
      );
    },
  });
}

/**
 * Creates a set of base styles for a icon
 */
export function createBaseMapIconStyle(
  iconPath: string,
  name: string | undefined,
  color: string,
  fontSize: number,
  fontFamily: string,
  textOffset: number,
  useBackingPlate: boolean = true,
  inverted: boolean = true,
  isBold: boolean = false
): Style[] {
  return [
    new Style({
      image: useBackingPlate
        ? createCircleBackingPlateStyle(isBold ? 4 : 0)
        : undefined,
      text: new Text({
        text: name,
        font: `${fontSize}px ${fontFamily}`,
        offsetY: textOffset * renderScale,
        scale: renderScale,
        stroke: new Stroke({
          color: inverted ? "#000000" : "#FFFFFF",
          width: 8,
        }),
        fill: new Fill({
          color: inverted ? "#FFFFFF" : color,
        }),
      }),
    }),
    new Style({
      image: new Icon({
        src: iconPath,
        scale: renderScale,
      }),
    }),
  ];
}

/**
 * Creates a circle style, mainly used behind icons
 */
export function createCircleBackingPlateStyle(
  outline: number = 0
): CircleStyle {
  return new CircleStyle({
    radius: (28 + outline) * renderScale,
    fill: new Fill({
      color: "#FFFFFF",
    }),
  });
}

/**
 * Creates a new color using the given hex color string and a alpha value
 */
export function colorWithAlpha(color: string, alpha: number): string {
  const [r, g, b] = Array.from(colorAsArray(color));
  return colorAsString([r, g, b, alpha]);
}

/**
 * Creates a base feature for a icon using the given style
 */
export function createBaseMapIconFeature(
  point: Point,
  style: Style | Style[],
  type?: string,
  id?: string
): Feature<Geometry> {
  const feature = new Feature<Geometry>({
    geometry: point,
  });
  feature.setStyle(style);
  feature.set(OBJECT_TYPE, type);
  feature.set(OBJECT_ID, id);
  return feature;
}

/**
 * Creates a styled feature for a gps indicator icon
 */
export function createGpsIndicatorFeature(point: Point): Feature<Geometry> {
  const feature = new Feature({
    geometry: point,
  });

  const style = new Style({
    image: new Icon({
      src: ICONS.GPS_POSITION,
      scale: renderScale,
    }),
  });
  feature.setStyle(style);
  return feature;
}

/**
 * Creates a styled feature for a snowmobile path icon
 */
export function createSnowmobilingIconFeature(
  point: Point,
  name: string | undefined,
  isBold: boolean = false
): Feature<Geometry> {
  return createBaseMapIconFeature(
    point,
    createBaseMapIconStyle(
      ICONS.PATHS.SNOWMOBILING,
      name,
      COLORS.PATHS.SNOWMOBILING,
      30,
      DEFAULT_ICON_FONT,
      45,
      true,
      false,
      isBold
    )
  );
}

/**
 * Creates a styled feature for a common path icon
 */
export function createCommonPathIconFeature(
  point: Point,
  id: string,
  name: string | undefined,
  color: string,
  iconPath: string,
  isBold: boolean = false
): Feature<Geometry> {
  return createBaseMapIconFeature(
    point,
    createBaseMapIconStyle(
      iconPath,
      name,
      color,
      30,
      DEFAULT_ICON_FONT,
      45,
      true,
      false,
      isBold
    ),
    FeatureType.Path,
    id
  );
}

/**
 * Creates a styled feature for a common poi icon
 */
export function createCommonPoiIconFeature(
  point: Point,
  id: string,
  name: string | undefined,
  color: string,
  iconPath: string,
  inverted: boolean = false,
  isBold: boolean = false
): Feature<Geometry> {
  return createBaseMapIconFeature(
    point,
    createBaseMapIconStyle(
      iconPath,
      name,
      color,
      20,
      DEFAULT_ICON_FONT,
      30,
      isBold,
      inverted,
      false
    ),
    FeatureType.Poi,
    id
  );
}

/**
 * Creates a styled feature for a path endcap icon
 */
export function createEndcapIconFeature(
  point: Point,
  name: string | undefined,
  inverted: boolean
): Feature<Geometry> {
  return createBaseMapIconFeature(
    point,
    createBaseMapIconStyle(
      ICONS.PATHS.ENDCAP,
      name,
      COLORS.PATHS.ENDCAP,
      20,
      DEFAULT_ICON_FONT,
      30,
      false,
      inverted
    )
  );
}

/**
 * Creates a set of base styles for paths
 */
export function createBasePathStyles(
  color: string,
  opacity: number,
  width: number = 6,
  outline: number = 8
): Style[] {
  return [
    new Style({
      stroke: new Stroke({
        color: "#FFFFFF",
        width: (width + outline) * renderScale,
      }),
    }),
    new Style({
      stroke: new Stroke({
        color: colorWithAlpha(color, opacity),
        width: width * renderScale,
      }),
    }),
  ];
}

/**
 * Creates a base text style for paths
 */
export function createBasePathTextStyle(color: string, name?: string): Style {
  return new Style({
    text: new Text({
      text: name,
      font: `20px ${DEFAULT_PATH_FONT}`,
      scale: renderScale,
      stroke: new Stroke({
        color: "#FFFFFF",
        width: 8,
      }),
      fill: new Fill({
        color,
      }),
    }),
  });
}

export function mapAoiStyle(
  color: string,
  opacity: number,
  width = 0,
  outline = 0
) {
  return [
    new Style({
      stroke: new Stroke({
        color: "#FFFFFF",
        width: (width + outline) * renderScale,
      }),
    }),
    new Style({
      fill: new Fill({ color: colorWithAlpha(color, opacity / 2) }),
    }),
  ];
}

/**
 * Creates a set of styled base featureas for a path using the given style
 */
export function createBasePathFeatures(
  coordinates: number[][],
  type: string,
  id: string,
  texts: { color: string; name: string; position: number }[],
  pathStyle: Style | Style[]
): Feature<Geometry>[] {
  const features = [];
  const pathFeature = new Feature({
    geometry: new LineString(coordinates, "XYM"),
  });
  pathFeature.set(OBJECT_TYPE, type);
  pathFeature.set(OBJECT_ID, id);
  pathFeature.setStyle(pathStyle);
  features.push(pathFeature);

  texts.forEach((text) => {
    const index = Math.floor(coordinates.length * text.position);
    const textFeature = new Feature({
      geometry: new Point(coordinates[index]),
    });
    textFeature.setStyle(createBasePathTextStyle(text.color, text.name));
    features.push(textFeature);
  });

  return features;
}

/**
 * Creates a set of styled features for a snowmobile path
 */
export function createSnowmobilePathFeatures(
  coordinates: number[][],
  id: string,
  isClosed: boolean,
  isBold: boolean,
  color?: string
): Feature<Geometry>[] {
  return createBasePathFeatures(
    coordinates,
    FeatureType.SnowmobileSubPath,
    id,
    isClosed
      ? [{ name: CLOSED_TEXT, position: 0.5, color: COLORS.PATHS.SNOWMOBILING }]
      : [],
    createBasePathStyles(
      color ?? COLORS.PATHS.SNOWMOBILING,
      isClosed ? 0.3 : 1,
      6,
      isBold ? 16 : 8
    )
  );
}

export function mapSnowmobileFraFeatures(
  points: number[][],
  value: string,
  texts: { name: string; color: string; position: number }[],
  fraStyle: Style[],
  center: number[]
) {
  const features = [];
  const feature = new Feature({
    id: value,
    name: value,
    geometry: new Polygon([points]),
  });
  feature.setStyle(fraStyle);
  features.push(feature);

  feature.set(OBJECT_TYPE, FeatureType.SnowmobileFra);
  feature.set(OBJECT_ID, value);

  texts.forEach((text) => {
    const textFeature = new Feature({
      geometry: new Point(center),
    });
    textFeature.setStyle(createBasePathTextStyle("#000000", text.name));
    features.push(textFeature);
  });
  return features;
}

/**
 * Creates a set of styled features for a common path
 */
export function createCommonPathFeatures(
  coordinates: number[][],
  id: string,
  color: string,
  isClosed: boolean,
  isBold: boolean
): Feature<Geometry>[] {
  return createBasePathFeatures(
    coordinates,
    FeatureType.Path,
    id,
    isClosed
      ? [
          { name: CLOSED_TEXT, position: 0.25, color },
          { name: CLOSED_TEXT, position: 0.75, color },
        ]
      : [],
    createBasePathStyles(color, isClosed ? 0.3 : 1, 6, isBold ? 16 : 8)
  );
}

/**
 * Post a message to the host app
 */
export function postMessage(msg: string) {
  console.log("new map message:", msg);

  if ((window as any).ReactNativeWebView) {
    (window as any).ReactNativeWebView.postMessage(msg);
  }
}

export function getIconFromIdentifier(identifier: string) {
  switch (identifier) {
    case "CATEGORY_1":
      return ICONS.PATHS.CROSS_COUNTRY_SKIING;
    case "CATEGORY_2":
      return ICONS.PATHS.SNOWMOBILING;
    case "CATEGORY_3":
      return ICONS.PATHS.MOUNTAIN_BIKING;
    case "CATEGORY_4":
      return ICONS.PATHS.HIKING;
    case "CATEGORY_5":
      return ICONS.PATHS.WINTER_HIKING;
    case "CATEGORY_6":
      return ICONS.PATHS.ROMBO;
    case "CATEGORY_7":
      return ICONS.PATHS.CULTURES;
  }
}

export function clearTimeouts() {
  const highestId = window.setTimeout(() => {
    for (let i = highestId; i >= 0; i--) {
      window.clearInterval(i);
      window.clearTimeout(i);
    }
  }, 0);
}
